import React, { useEffect, useRef } from "react";

// const TestDonutChart3 = ({ xc, yc, r, totalResult = [], colorArray }) => {
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     // Get the device pixel ratio for high-DPI displays
//     const dpr = window.devicePixelRatio || 1;

//     // Set the canvas size with scaling for high resolution
//     canvas.width = 90 * dpr;
//     canvas.height = 90 * dpr;
//     ctx.scale(dpr, dpr); // Scale the context to match the DPI

//     function drawHalfCircle(xc, yc, r, startAngle, endAngle, gradientColors) {
//       const xStart = xc + Math.cos(startAngle) * r;
//       const xEnd = xc + Math.cos(endAngle) * r;
//       const yStart = yc + Math.sin(startAngle) * r;
//       const yEnd = yc + Math.sin(endAngle) * r;

//       const gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
//       gradient.addColorStop(0, gradientColors[0]);
//       gradient.addColorStop(0.5, gradientColors[0]);
//       gradient.addColorStop(1.0, gradientColors[1]);

//       ctx.beginPath();
//       ctx.strokeStyle = gradient;
//       ctx.arc(xc, yc, r, startAngle, endAngle);
//       ctx.lineWidth = 15;
//       ctx.stroke();
//       ctx.closePath();
//     }

//     // Clear the canvas
//     ctx.clearRect(0, 0, canvas.width, canvas.height);

//     const fullCircle = 2 * Math.PI;

//     const total = totalResult?.total?.value ?? 0;
//     const firstArcValue = totalResult?.[0]?.[0]?.percentage / 100 || 0;
//     const secondArcValue = totalResult?.[0]?.[1]?.percentage / 100 || 0;

//     if (firstArcValue === 1) {
//       ctx.beginPath();
//       ctx.arc(xc, yc, r, 0, fullCircle);
//       ctx.lineWidth = 15;
//       ctx.strokeStyle = colorArray[0][0];
//       ctx.stroke();
//       ctx.closePath();
//     } else {
//       const firstArcEnd = fullCircle * firstArcValue;
//       const secondArcStart = firstArcEnd;

//       drawHalfCircle(xc, yc, r, 0, firstArcEnd, [colorArray[0][0], "#F7F7F7"]);
//       drawHalfCircle(xc, yc, r, secondArcStart, fullCircle, [
//         colorArray[1][0],
//         "#F7F7F7",
//       ]);
//     }

//     const bodyFontFamily = getComputedStyle(document.body).fontFamily;
//     const dataTotalString = String(total);

//     ctx.fillStyle = "#000000";
//     ctx.textAlign = "center";
//     ctx.textBaseline = "middle";

//     // Adjust the font size and position text
//     ctx.font = `bold ${12 * dpr}px ${bodyFontFamily}`;
//     ctx.fillText(`${dataTotalString}`, xc, yc - 10);

//     ctx.font = `${9 * dpr}px ${bodyFontFamily}`;
//     ctx.fillText("Employee", xc, yc + 5);
//   }, [xc, yc, r, colorArray, totalResult]);

//   return (
//     <div
//       className="flex justify-center items-center"
//       style={{ width: 90, height: 90 }}
//     >
//       <canvas
//         ref={canvasRef}
//         width={90}
//         height={90}
//         style={{ width: "90px", height: "90px" }}
//       />
//     </div>
//   );
// };

const TestDonutChart3 = ({ xc, yc, r, totalResult = {}, colorArray }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const dpr = window.devicePixelRatio || 1;
    canvas.width = 90 * dpr;
    canvas.height = 90 * dpr;
    ctx.scale(dpr, dpr);

    function drawHalfCircle(xc, yc, r, startAngle, endAngle, gradientColors) {
      const xStart = xc + Math.cos(startAngle) * r;
      const xEnd = xc + Math.cos(endAngle) * r;
      const yStart = yc + Math.sin(startAngle) * r;
      const yEnd = yc + Math.sin(endAngle) * r;

      const gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
      gradient.addColorStop(0, gradientColors[0]);
      gradient.addColorStop(0.5, gradientColors[0]);
      gradient.addColorStop(1.0, gradientColors[1]);

      ctx.beginPath();
      ctx.strokeStyle = gradient;
      ctx.arc(xc, yc, r, startAngle, endAngle);
      ctx.lineWidth = 15;
      ctx.stroke();
      ctx.closePath();
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const fullCircle = 2 * Math.PI;
    const total = totalResult?.total?.value ?? 0;

    // Find the key that contains the percentage array (e.g., 'employeeTypes' or 'genders')
    const dynamicKey = Object.keys(totalResult).find(
      (key) => Array.isArray(totalResult[key]) && key !== "total"
    );

    const percentageData = totalResult[dynamicKey] || [];

    const firstArcValue = percentageData?.[0]?.percentage / 100 || 0;
    const secondArcValue = percentageData?.[1]?.percentage / 100 || 0;

    if (firstArcValue === 1) {
      ctx.beginPath();
      ctx.arc(xc, yc, r, 0, fullCircle);
      ctx.lineWidth = 15;
      ctx.strokeStyle = colorArray[0][0];
      ctx.stroke();
      ctx.closePath();
    } else {
      const firstArcEnd = fullCircle * firstArcValue;
      const secondArcStart = firstArcEnd;

      drawHalfCircle(xc, yc, r, 0, firstArcEnd, [colorArray[0][0], "#F7F7F7"]);
      drawHalfCircle(xc, yc, r, secondArcStart, fullCircle, [
        colorArray[1]?.[0] || "#CCCCCC",
        "#F7F7F7",
      ]);
    }

    // Adjust font size based on canvas size
    const fontSize = Math.max(12, r * 0.3); // Min 8px, scales with radius
    const smallFontSize = Math.max(10, r * 0.2); // Min 6px for "Employee" text

    const bodyFontFamily = getComputedStyle(document.body).fontFamily;
    ctx.fillStyle = "#000000";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.font = `bold ${fontSize}px ${bodyFontFamily}`;
    ctx.fillText(`${total}`, xc, yc - 10);

    ctx.font = `${smallFontSize}px ${bodyFontFamily}`;
    ctx.fillText("Employee", xc, yc + 5);
  }, [xc, yc, r, colorArray, totalResult]);

  return (
    <div
      className="flex justify-center items-center"
      style={{ width: 90, height: 90 }}
    >
      <canvas
        ref={canvasRef}
        width={90}
        height={90}
        style={{ width: "90px", height: "90px" }}
      />
    </div>
  );
};

const TestDonutChart2 = ({ xc, yc, r, data = [], colorArray }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    function drawArc(xc, yc, r, startAngle, endAngle, gradientColors) {
      const xStart = xc + Math.cos(startAngle) * r;
      const xEnd = xc + Math.cos(endAngle) * r;
      const yStart = yc + Math.sin(startAngle) * r;
      const yEnd = yc + Math.sin(endAngle) * r;

      const gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);

      // Adjust color stop to give more weight to the first color
      gradient.addColorStop(0, gradientColors[0]); // Start with first color
      // gradient.addColorStop(0.85, gradientColors[0]);   // Keep first color until 85% of the gradient
      gradient.addColorStop(1.0, gradientColors[1]); // Last 15% is the second color

      ctx.beginPath();
      ctx.strokeStyle = gradient;
      ctx.arc(xc, yc, r, startAngle, endAngle);
      ctx.lineWidth = 15;
      ctx.stroke();
      ctx.closePath();
    }

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate angles based on data (in radians)
    const total = data?.reduce((acc, item) => acc + Object.values(item)[0], 0);
    const firstArcValue = (Object.values(data[0])[0] / total) * 2 * Math.PI; // 75% or 3/4 of the circle
    const secondArcValue = 2 * Math.PI - firstArcValue; // Remaining 25%

    // First arc
    drawArc(xc, yc, r, -Math.PI / 2, -Math.PI / 1.5 + firstArcValue, [
      colorArray[0][0],
      "#F7F7F7",
    ]);

    // Second arc
    drawArc(xc, yc, r, secondArcValue, (3 * Math.PI) / 2, [
      colorArray[1][0],
      "#F7F7F7",
    ]);

    // Get the font family from the body element
    const bodyFontFamily = getComputedStyle(document.body).fontFamily;

    ctx.fillStyle = "#000000";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    // Position the number text slightly above the center and make it larger
    ctx.font = `bold 12px ${bodyFontFamily}`;
    ctx.fillText(Object.keys(data[0])[0] + "%", xc, yc - 10); // Display percentage value of first arc

    // Position the 'Employee' text slightly below the center
    ctx.font = `9px ${bodyFontFamily}`;
    ctx.fillText(Object.keys(data[1])[0], xc, yc + 5); // Display the label of the first arc
  }, [xc, yc, r, colorArray, data]);

  return (
    <div
      className="flex justify-center items-center"
      style={{ width: 90, height: 90 }}
    >
      <canvas ref={canvasRef} width={90} height={90} />
    </div>
  );
};

export { TestDonutChart2, TestDonutChart3 };
