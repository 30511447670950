import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Onboarding,
  InputText,
  InputPassword,
  Button,
  CheckBox,
} from "@bluesilodev/timhutcomponents";

import { handlePostLogin } from "services/employeeAPI";
import { setLoginReducer } from "store/reducer/user";

import ImgLogin from "assets/images/logo_login.png";
import { LogoTimhut } from "assets/icons";
import { SignSvg, UserIcon, LockSvg } from "assets/icons";

import { loginSchema } from "./schema";
import { alertError, alertSuccess } from "utils/alert";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // USING USEEFFECT TO SAVE TO LOCALSTORAGE IF REMEMBER ME TRUE
  useEffect(() => {
    const remember = JSON.parse(localStorage.getItem("rememberMe"));

    if (remember) {
      setValues({
        ...values,
        email: remember.email,
        password: remember.password,
        rememberMe: remember.rememberMe,
      });
    }
  }, []);

  // FORMIK FOR LOGIN
  const {
    handleSubmit,
    values,
    errors,
    handleBlur,
    handleChange,
    getFieldProps,
    touched,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: loginSchema,
    onSubmit: async () => {
      const { email, password } = values;

      try {
        const resp = await handlePostLogin({ email, password });

        // Check if response is okay before destructuring
        if (resp && resp.data) {
          const { token, expired, payLoad } = resp.data;

          const allData = {
            currentUser: payLoad,
            token: token,
            expired: expired,
          };

          // console.log('login data', allData.currentUser.role[0]);
          const currentUserRole = payLoad?.role?.[0] || null;
          // console.log('current user role', currentUserRole)

          // Store user data and tokens in localStorage for persistent session management
          localStorage.setItem("currentUser", JSON.stringify(allData));
          localStorage.setItem("accessToken", token);
          localStorage.setItem("currentRole", currentUserRole);

          dispatch(
            setLoginReducer({
              currentUser: payLoad,
              token,
              expired,
              currentRole: currentUserRole,
            })
          );

          // Save to the localStorage if rememberMe is checked
          if (values.rememberMe) {
            const userData = {
              email: values.email,
              password: values.password,
              rememberMe: values.rememberMe,
            };
            localStorage.setItem("rememberMe", JSON.stringify(userData));
          } else {
            localStorage.removeItem("rememberMe");
          }

          alertSuccess("Success Login");
          resetForm();

          if (payLoad.role.includes("SuperAdmin")) {
            window.location.href = "/employee/super/organization";
            return;
          }

          window.location.href = "/";
        } else {
          if (resp?.status === 401) {
            alertError(`Login Error: ${resp.message}`);
            console.error(`Login error: ${resp.message}`);
            return;
          }
          alertError("Login Error: No data received from server");
          console.error("Login error: No data received from server");
        }
      } catch (error) {
        alertError("Login Error: Something went wrong");
        console.error("Login error:", error);
      }
    },
  });

  return (
    <>
      <Onboarding source={ImgLogin}>
        <div className="flex w-full h-full">
          <div className="w-full">
            <div className="flex justify-around w-full gap-5 mb-4 sm:gap-0 sm:mb-0 ">
              <Button
                className={" sm:w-[300px] w-[500px] p-3 sm:p-0 "}
                onClick={() => {
                  navigate("/registerCompany");
                }}
                label={
                  <div className="flex items-center gap-3 ">
                    <SignSvg />

                    <h1>Sign Up as Company</h1>
                  </div>
                }
                type={"button"}
                style="solid"
                width={200}
              />

              <Button
                className={" sm:w-[300px] w-[500px] p-3 sm:p-0"}
                onClick={() => {
                  navigate("/registerEmployee");
                }}
                label={
                  <div className="flex items-center gap-3 ">
                    <SignSvg />

                    <h1>Sign Up as Employee</h1>
                  </div>
                }
                type={"button"}
                style="solid"
                width={200}
              />
            </div>

            <form
              autoComplete="off"
              className="flex flex-col w-full mt-20"
              onSubmit={handleSubmit}
            >
              <LogoTimhut />
              <div className="pt-10 pb-5">
                <h1 className="text-2xl font-bold font font-latoBold">Login</h1>
                <p className="font-latoRegular">
                  Use your credentials to access your account
                </p>
              </div>

              <div className="flex flex-col w-full gap-3 font-latoRegular">
                <div className="">
                  {/* ERROR HARUS DIKOMPONENT ERROR MESSAGE */}
                  <InputText
                    id={"email"}
                    title={"Email"}
                    value={values.email}
                    placeholder="Enter Email"
                    className={"flex items-center w-full gap-2 "}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    label={
                      <>
                        <UserIcon />
                      </>
                    }
                    error={
                      <>
                        {errors.email && touched.email && (
                          <p className="text-red-500">{errors.email}</p>
                        )}
                      </>
                    }
                    icon={
                      <>
                        <LockSvg />
                      </>
                    }
                  />
                </div>
                <div>
                  <InputPassword
                    name={"password"}
                    title={"Password"}
                    placeholder="Enter Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                          stroke="#1A1A2E"
                          strokeWidth="2"
                        />
                        <path
                          d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                          stroke="#1A1A2E"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <circle cx="12" cy="15" r="2" fill="#1A1A2E" />
                      </svg>
                    }
                    required={true}
                    className={"flex items-center w-full gap-2"}
                    icon={
                      <div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                            stroke="#1A1A2E"
                            strokeWidth="2"
                          />
                          <path
                            d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                            stroke="#1A1A2E"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <circle cx="12" cy="15" r="2" fill="#1A1A2E" />
                        </svg>
                      </div>
                    }
                    error={
                      <>
                        {errors.password && touched.password && (
                          <p className="text-red-500">{errors.password}</p>
                        )}
                      </>
                    }
                    // required={true}
                  />
                </div>
              </div>
              <div className="flex justify-between w-full my-3">
                <div className="flex items-center gap-2">
                  <CheckBox
                    classname={"flex items-center"}
                    value={values.rememberMe}
                    nameInput={"rememberMe"}
                    id="rememberMe"
                    {...getFieldProps("rememberMe")}
                    onChange={(v) => setFieldValue("rememberMe", v)}
                    label={"Remember me"}
                  />
                </div>

                <div className="flex items-center mb-1">
                  <Link to={"/forgotPassword"} className="text-[#DD7224]">
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <Button
                className={"w-full mt-3"}
                style="solid"
                type="submit"
                label={
                  <div className="flex items-center gap-3 ">
                    <SignSvg />

                    <h1>Sign In</h1>
                  </div>
                }
              />
            </form>
          </div>
        </div>
      </Onboarding>
    </>
  );
}

export default LoginPage;
