import React from "react";

function CameraIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10023_6365)">
        <path
          d="M30.6667 25.3333C30.6667 26.0406 30.3858 26.7189 29.8857 27.219C29.3856 27.719 28.7073 28 28 28H4.00004C3.2928 28 2.61452 27.719 2.11442 27.219C1.61433 26.7189 1.33337 26.0406 1.33337 25.3333V10.6667C1.33337 9.95942 1.61433 9.28115 2.11442 8.78105C2.61452 8.28095 3.2928 8 4.00004 8H9.33337L12 4H20L22.6667 8H28C28.7073 8 29.3856 8.28095 29.8857 8.78105C30.3858 9.28115 30.6667 9.95942 30.6667 10.6667V25.3333Z"
          stroke="#8A8A8A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 22.6667C18.9455 22.6667 21.3333 20.2789 21.3333 17.3333C21.3333 14.3878 18.9455 12 16 12C13.0544 12 10.6666 14.3878 10.6666 17.3333C10.6666 20.2789 13.0544 22.6667 16 22.6667Z"
          stroke="#8A8A8A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10023_6365">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CameraIcon;
