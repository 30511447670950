import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

import {
  Button,
  CheckBox,
  InputSelect,
  InputText,
  TextArea,
  UploadImg1,
} from "@bluesilodev/timhutcomponents";
import TestInputDate from "components/testInputDate";
import { handlePostEvent, useGetLocationQuery } from "services/employeeAPI";

import { alertError, alertSuccess } from "utils/alert";
import { validationSchema } from "./schema";

import { useMutation, useQueryClient } from "@tanstack/react-query";

const EventForm = ({ onClose }) => {
  const [locationDataApi, setLocationDataApi] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [duration, setDuration] = useState(0); // Changed to numeric to store days duration
  const [eventDates, setEventDates] = useState({
    startDate: "",
    endDate: "",
  });
  const queryClient = useQueryClient();
  const { mutateAsync: createEvent, isLoading } = useMutation({
    mutationFn: async (formData) => {
      return await handlePostEvent(formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getEvent"]);
    },
  });

  const { data: resLocation, isSuccess: isSuccessLocation } =
    useGetLocationQuery();

  // Fetch locations and set options
  useEffect(() => {
    if (isSuccessLocation && resLocation?.data) {
      setLocationDataApi(resLocation.data.data || []);
      setLocationOption(
        resLocation.data.data.map((value) => ({
          label: value.locationName,
          value: value.locationName,
        })) || []
      );
    }
  }, [isSuccessLocation, resLocation]);

  useEffect(() => {
    const { startDate, endDate } = eventDates;

    if (startDate && endDate) {
      console.log("startDate", startDate);
      const [startDay, startMonth, startYear] = startDate.split("/");
      const [endDay, endMonth, endYear] = endDate.split("/");

      const start = new Date(startYear, startMonth - 1, startDay);
      const end = new Date(endYear, endMonth - 1, endDay);

      const timeDiff = end.getTime() - start.getTime();
      if (timeDiff >= 0) {
        setDuration(timeDiff / (1000 * 3600 * 24)); // Set duration in days
      } else {
        setDuration(0); // Reset duration if dates are invalid
      }
    }
  }, [eventDates]);

  const eventlabel = (
    <div className="flex justify-center w-full gap-2">
      <EventIcon />
      <p className="text-sm">Create New Events</p>
    </div>
  );

  return (
    <Formik
      initialValues={{
        nameEvent: "",
        startDate: "",
        endDate: "",
        duration: 0,
        isCompanyHoliday: false,
        posterAttachment: "",
        note: "",
        location: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          const formData = new FormData();

          const [startDay, startMonth, startYear] = values.startDate.split("/");
          const [endDay, endMonth, endYear] = values.endDate.split("/");

          // Handle two-digit year assumption by converting it to the correct century
          const adjustedStartYear =
            startYear.length === 2 ? `20${startYear}` : startYear;
          const adjustedEndYear =
            endYear.length === 2 ? `20${endYear}` : endYear;

          // Create start and end dates correctly
          const start = new Date(adjustedStartYear, startMonth - 1, startDay);
          const end = new Date(adjustedEndYear, endMonth - 1, endDay);

          // Append values to formData
          for (const key in values) {
            formData.append(key, values[key]);
          }

          formData.set("duration", duration); // Set duration from state
          formData.set("startDate", start);
          formData.set("endDate", end);

          // Log form data contents
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
          }

          const resp = await createEvent(formData);
          if (resp?.success === true && resp?.data && resp?.data?.data) {
            console.log("Event submission successful");
            alertSuccess("Success Sending Event Data");
            onClose(); // Close the form or modal after successful submission
          }
        } catch (error) {
          console.error("Error during event submission:", error);
          alertError(
            `Failed to send Event Data: ${error.response?.data?.message || ""}`
          );
        }
      }}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
      }) => {
        const changeFile = (e) => {
          const file = e.currentTarget.files[0];
          console.log("file set run");

          if (file) {
            // Use setFieldValue to update Formik state
            setFieldValue("posterAttachment", file);
          }
        };

        const handleDateChange = (field, date, setFieldValue) => {
          setFieldValue(field, date);
          setEventDates((prevState) => ({
            ...prevState,
            [field]: date,
          }));
        };

        return (
          <Form>
            <div className="grid grid-cols-2 gap-6 p-3 form-group">
              <div className="col-span-1">
                <InputText
                  name="nameEvent"
                  placeholder="Enter Name of Events"
                  title={"Name of Events"}
                  value={values.nameEvent}
                  required={true}
                  label={null}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.nameEvent && touched.nameEvent && errors.nameEvent
                  }
                />
              </div>
              <div className="col-span-1">
                <InputSelect
                  name="location"
                  title={"Locations"}
                  options={locationOption}
                  value={values.location}
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.location && touched.location && errors.location}
                />
              </div>
              <div className="col-span-1">
                <TestInputDate
                  name="startDate"
                  label={"Start Date"}
                  value={values.startDate}
                  onChange={(date) =>
                    handleDateChange("startDate", date, setFieldValue)
                  }
                  disabledPastDate={true}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={
                    errors.startDate && touched.startDate && errors.startDate
                  }
                  required={true}
                />
              </div>
              <div className="col-span-1">
                <TestInputDate
                  name="endDate"
                  label={"End Date"}
                  value={values.endDate}
                  onChange={(date) =>
                    handleDateChange("endDate", date, setFieldValue)
                  }
                  disabledPastDate={true}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={errors.endDate && touched.endDate && errors.endDate}
                  required={true}
                />
              </div>
              <div className="items-center col-span-1">
                <InputText
                  title="Durations"
                  placeholder="Enter Duration"
                  value={duration + " Day(s)"}
                  required={true}
                  className="w-full flex items-center gap-2 [&_*]:text-[#5B5B6A]"
                  disabled
                />
              </div>
              <div className="flex items-center col-span-1">
                <div className="flex items-center w-full gap-3">
                  <CheckBox
                    label="Is This Company Holiday"
                    nameInput="isCompanyHoliday"
                    value={values.isCompanyHoliday}
                    onChange={() => {
                      setFieldValue(
                        "isCompanyHoliday",
                        !values.isCompanyHoliday
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-span-2">
                <UploadImg1
                  required={false}
                  onChange={changeFile} // Call changeFile to handle file change
                  textSizeLimit={
                    "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
                  }
                  title={"Poster Attachments"}
                  onBlur={handleBlur}
                  name="posterAttachment"
                  accept="image/jpeg, image/jpg, image/png"
                  message={
                    errors.posterAttachment && touched.posterAttachment ? (
                      <div className="text-red-500">
                        {errors.posterAttachment}
                      </div>
                    ) : (
                      values?.posterAttachment[0]?.name ||
                      values?.posterAttachment?.name
                    )
                  }
                />
              </div>
            </div>
            <div className="col-span-2 mt-4">
              <TextArea
                name="note"
                label={"Notes"}
                rows={4}
                value={values.note}
                onChange={handleChange}
                error={touched.note && errors.note ? errors.note : ""}
              />
            </div>
            <div className="grid w-full gap-6 mt-4">
              <Button
                type="submit"
                className="w-full h-[50px]"
                label={isLoading ? "Loading..." : eventlabel}
                disabled={isLoading}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

function EventIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.83325 8.00033C1.83325 6.75417 1.83325 6.13109 2.1012 5.66699C2.27674 5.36295 2.52921 5.11048 2.83325 4.93494C3.29735 4.66699 3.92043 4.66699 5.16659 4.66699H11.8333C13.0794 4.66699 13.7025 4.66699 14.1666 4.93494C14.4706 5.11048 14.7231 5.36295 14.8986 5.66699C15.1666 6.13109 15.1666 6.75417 15.1666 8.00033C15.1666 9.24648 15.1666 9.86956 14.8986 10.3337C14.7231 10.6377 14.4706 10.8902 14.1666 11.0657C13.7025 11.3337 13.0794 11.3337 11.8333 11.3337H5.16659C3.92043 11.3337 3.29735 11.3337 2.83325 11.0657C2.52921 10.8902 2.27674 10.6377 2.1012 10.3337C1.83325 9.86956 1.83325 9.24648 1.83325 8.00033Z"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M13.1666 2.66634C13.1666 1.92996 12.5696 1.33301 11.8333 1.33301H5.16659C4.43021 1.33301 3.83325 1.92996 3.83325 2.66634"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M13.1666 13.333C13.1666 14.0694 12.5696 14.6663 11.8333 14.6663H5.16659C4.43021 14.6663 3.83325 14.0694 3.83325 13.333"
        stroke="currentColor"
        stroke-width="2"
      />
      <rect
        x="4.16659"
        y="7.00033"
        width="3.33333"
        height="0.666667"
        rx="0.333333"
        stroke="currentColor"
        stroke-width="0.666667"
      />
    </svg>
  );
}

export default EventForm;
