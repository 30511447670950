import { useQuery } from "@tanstack/react-query";

import { customAxios } from "utils/axios";

const useGetLeaveQuery = () => {
  return useQuery(["getLeave"], async () => {
    const { data } = await customAxios.get(`/api/leave/thisMonth`);
    return data;
  });
};

const getAllLeaveByDate = async (val) => {
  try {
    const res = await customAxios.get(
      `/api/leave/getDate?startDate=${val.startDate}&endDate=${val.endDate}`
    );
    return res.data;
  } catch (error) {
    console.error("Error fetching leave data:", error);
    throw error; // Rethrow the error or handle it as needed
  }
};

export { useGetLeaveQuery, getAllLeaveByDate };
