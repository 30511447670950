import { useQuery } from "@tanstack/react-query";

import { customAxios } from "utils/axios";

const useGetEmployeeShiftQuery = (props = {}) => {
  const { firstName, location, jobPosition, department, startDate, endDate } =
    props;

  const getEmployee = async () => {
    const queryParams = new URLSearchParams();

    if (firstName) queryParams.append("firstName", firstName);
    if (location) queryParams.append("location", location);
    if (jobPosition) queryParams.append("jobPosition", jobPosition);
    if (department) queryParams.append("departmentName", department);
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);

    // Build the URL based on whether there are query parameters
    const url = queryParams.toString()
      ? `/api/shift/getShiftData/employee?${queryParams}`
      : "/api/shift/getShiftData/employee";

    const { data } = await customAxios.get(url);
    return data;
  };

  return useQuery(
    ["getEmployee", firstName, location, jobPosition],
    getEmployee,
    {
      onError: (err) => {
        console.log("Error fetching Employee Data ", err);
      },
    }
  );
};

const useGetLocationQuery = () => {
  return useQuery(
    ["getLocation"],
    async () => {
      const { data } = await customAxios.get("/api/shift/employee/location");
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching Location Data ", err);
      },
    }
  );
};

const useGetShiftQuery = () => {
  return useQuery(
    ["getAllShift"],
    async () => {
      const { data } = await customAxios.get("/api/shift");
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching all shift Data", err);
      },
    }
  );
};

const useGetAttendanceByShift = (shiftId) => {
  return useQuery(
    ["getShift"],
    async () => {
      const { data } = await customAxios.get(`/api/shift/${shiftId}`);
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching shift Data", err);
      },
    }
  );
};

const useGetDashboardQuery = ({ fromDate, toDate }) => {
  return useQuery(
    ["getDashboard", fromDate, toDate], // Add dates to the query key for caching
    async () => {
      const endPoint = `/api/shift/assignshift/dashboard?fromDate=${fromDate}&toDate=${toDate}`;
      const { data } = await customAxios.get(endPoint);
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching dashboard data: ", err);
      },
      enabled: !!fromDate && !!toDate, // Only run query if dates are provided
    }
  );
};

export const useGetByIdShift = ({ uId }) => {
  const getByIdShift = async () => {
    const resp = await customAxios.get(`/api/shift/${uId}`);

    return resp.data;
  };

  return useQuery({
    queryKey: ["getShiftByID"],
    queryFn: getByIdShift,
    onError: (err) => {
      console.log("Something Wrong ", err);
    },
  });
};

const API = `api/scheduling`;

export const useGetSchedulingShiftByUserID = (userID) => {
  return useQuery(["getSchedulingShift"], async () => {
    const res = await customAxios.get(`${API}/get-scheduling/${userID}`);
    return res.data;
  });
};

const getAttendanceByShift = async (shiftId) => {
  try {
    const { data } = await customAxios.get(`/api/shift/${shiftId}`);
    return data;
  } catch (error) {
    console.error("Error fetching shift data", error);
    throw error;
  }
};

export {
  useGetEmployeeShiftQuery,
  useGetLocationQuery,
  useGetShiftQuery,
  useGetAttendanceByShift,
  useGetDashboardQuery,
  getAttendanceByShift,
};
