import { useQuery, useMutation } from "@tanstack/react-query";

import { customAxios } from "utils/axios";
import { alertError, alertSuccess } from "utils/alert";

// NEW SIGN UP

export const postRegisterUserInformation = async (body) => {
  try {
    const res = await customAxios.post(
      `/api/employee/registerUserInformationModel`,
      body
    );

    console.log("userinform :", res.data);

    return res.data;
  } catch (error) {
    console.log("ERROR : ", error);
  }
};

export const postRegisterUser = async (body) => {
  try {
    const res = await customAxios.post(`/api/employee/registerUserModel`, body);
    return res.data;
  } catch (error) {
    console.log("ERROR : ", error);
  }
};

// END NEW SIGN UP

const handlePostRegisterEmployee = async (val) => {
  try {
    const res = await customAxios.post(`/api/employee/registerEmployee`, val);
    return res.data;
  } catch (error) {
    console.error("Register User Error:", error);
    alertError(error.response?.data?.message ?? "Failed to Register");
    throw error;
  }
};

const handlePostRegisterCompany = async (val) => {
  try {
    const res = await customAxios.post(`/api/employee/registerCompany`, val);
    return res.data;
  } catch (error) {
    console.error("Register Company Error:", error);
    alertError(error.response?.data?.message ?? "Failed to Register");
    throw error;
  }
};

const handlePostLogin = async (val) => {
  try {
    const res = await customAxios.post(`/api/auth/login`, val);
    return res.data;
  } catch (error) {
    console.error("Login error:", error);
    alertError(error.response?.data?.message ?? "Failed to login");
    throw error;
  }
};

const handlePostForgotPassword = async (val) => {
  try {
    const res = await customAxios.post(`/api/auth/forgotPassword`, val);
    return res.data;
  } catch (error) {
    console.error("Forgot Password error:", error);
    alertError(
      error.response?.data?.message ?? "Failed to Send Forgot Password Data"
    );
    throw error;
  }
};

const handlePostResetPassword = async (val) => {
  try {
    const res = await customAxios.post(`/api/auth/resetPassword`, val);
    return res.data;
  } catch (error) {
    console.error("Reset Password error:", error);
    alertError(
      error.response?.data?.message ?? "Failed to Send Reset Password Data"
    );
    throw error;
  }
};

const handlePostEvent = async (val) => {
  try {
    const res = await customAxios.post(`/api/event`, val);
    return res.data;
  } catch (error) {
    console.error("Event create error:", error);
    alertError(error.response?.data?.message ?? "Failed to Create Event");
    throw error;
  }
};

const useGetAllEmployeesQuery = () => {
  return useQuery(["getAllEmployees"], async () => {
    const { data } = await customAxios.get(`/api/employeeuser`);
    return data;
  });
};

const useGetEventQuery = (date) => {
  return useQuery(["getEvent", date], async () => {
    const { data } = await customAxios.get(`/api/event/thisMonth?date=${date}`);
    return data;
  });
};

const useGetBirthdayQuery = () => {
  return useQuery(["getBirthday"], async () => {
    const { data } = await customAxios.get(`/api/employeeuser/hbdEmployee`);
    return data;
  });
};

const useGetLocationQuery = () => {
  return useQuery(["getLocation"], async () => {
    const { data } = await customAxios.get(`/api/employee/getLocation`);
    return data;
  });
};

const useGetSettingByDefaultQuery = ({ onSuccess, onError }) => {
  return useQuery({
    queryKey: ["getSettingByDefault"],
    queryFn: async () => {
      const res = await customAxios.get(`/api/employee/getCompanySetting`);
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export {
  useGetEventQuery,
  useGetBirthdayQuery,
  useGetLocationQuery,
  useGetAllEmployeesQuery,
  handlePostLogin,
  handlePostRegisterCompany,
  handlePostRegisterEmployee,
  handlePostForgotPassword,
  handlePostResetPassword,
  handlePostEvent,
  useGetSettingByDefaultQuery,
};
