import dayjs from "dayjs";
import * as yup from "yup";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const today = dayjs().startOf("day");

export const validationSchema = yup.object().shape({
  nameEvent: yup.string().required("Name Of Event is Required"),
  startDate: yup
    .string()
    .required("Start Date is required")
    .test("is-future", "Start Date cannot be in the past", (val) => {
      if (!val) return false;
      const date = dayjs(val, "DD/MM/YYYY");
      return date.isValid() && !date.isBefore(today);
    })
    .test(
      "more than",
      "Start Date must be less than End Date",
      (val, context) => {
        const contextDate = context.parent.endDate;

        if (contextDate && val) {
          const [startDay, startMonth, startYear] = val.split("/");
          const joinDate = new Date(startYear, startMonth - 1, startDay);

          const [endDay, endMonth, endYear] = contextDate.split("/");
          const endDate = new Date(endYear, endMonth - 1, endDay);

          return endDate > joinDate;
        }
        return true;
      }
    ),

  endDate: yup
    .string()
    .required("End Date is required")
    .test("is-future", "End Date cannot be in the past", (val) => {
      if (!val) return false;
      const date = dayjs(val, "DD/MM/YYYY");
      return date.isValid() && !date.isBefore(today);
    })
    .test(
      "is-greater",
      "End Date must be greater than Start Date",
      function (val, context) {
        // 'this' refers to the entire object being validated
        const contextDate = context.parent.startDate;

        if (contextDate && val) {
          const [startDay, startMonth, startYear] = val.split("/");
          const endDate = new Date(startYear, startMonth - 1, startDay);

          const [endDay, endMonth, endYear] = contextDate.split("/");
          const joinDate = new Date(endYear, endMonth - 1, endDay);

          return endDate > joinDate;
        }
        return true;
      }
    ),
  posterAttachment: yup
    .mixed()
    .nullable()
    .test("fileSize", "The file is too large", (value) => {
      if (!value) return true;
      if (typeof value === "string") return true;
      return value.size <= 2000000;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .png",
      (value) => {
        if (!value) return true;
        if (typeof value === "string") return true;
        return ["image/jpeg", "image/png", "image/jpg"].includes(value.type);
      }
    ),
  location: yup.string().required("Location is Required"),
  note: yup.string().max(500, "Note cannot exceed 500 characters"),
});
