import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export const capitalize_first_letter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
