// import React, { useState } from "react";
// import { Calendar } from "react-date-range";
// import PropTypes from "prop-types";
// import { enGB } from 'date-fns/locale';
import dayjs from "dayjs";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./inputdate.css";

const TestInputDate = ({
  required,
  label,
  name,
  disabled,
  disabledPastDate = false,
  value,
  errors,
  setFieldValue,
  onChange,
  ...props
}) => {
  const [startDate, setStartDate] = useState(value ? new Date(value) : null);

  const formatDate = (date) => {
    return dayjs(date).format("DD/MM/YYYY");
  };

  const handleChange = (date) => {
    setStartDate(date);
    const formattedDate = date ? formatDate(date) : "";
    setFieldValue(name, formattedDate);
    // Call the external onChange function passed via props
    if (onChange) {
      onChange(formattedDate);
    }
  };

  return (
    <>
      <div
        className={`flex relative w-full ${
          disabled ? "bg-[#F2F4F5]" : ""
        } select-none`}
      >
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          dateFormat="dd/MM/yyyy"
          placeholderText="dd/mm/yyyy"
          disabled={disabled}
          minDate={disabledPastDate ? new Date() : undefined}
          name={name}
          className={`w-full h-[60px] px-3 pt-6 border ${
            disabled ? "bg-[#F2F4F5]" : "border-black"
          } rounded-md text-sm`}
          {...props}
        />
        <div className="flex items-center">
          <label
            className={`absolute left-3 top-3 transition-all text-sm duration-200 ease-in-out`}
          >
            {label} {required ? "*" : ""}
          </label>
          <div className="absolute right-[15px] top-[35%] cursor-pointer ">
            <CalendarSvg />
          </div>
        </div>
      </div>
      {errors && <div className={` text-red-500 pt-[7px]`}>{errors}</div>}
    </>
  );
};

function CalendarSvg() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09277 8.40445H18.9167"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.442 12.3088H14.4512"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0045 12.3088H10.0137"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.55769 12.3088H5.56695"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.442 16.1955H14.4512"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0045 16.1955H10.0137"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.55769 16.1955H5.56695"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0438 1V4.29078"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.96564 1V4.29078"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TestInputDate;

// export default TestInputDate;
