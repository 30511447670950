import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Button, Onboarding } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "flag-icons/css/flag-icons.min.css";

import {
  handlePostRegisterCompany,
  handlePostRegisterEmployee,
} from "services/employeeAPI";
import { useGetCSCQuery } from "services/commonAPI";

import { FiArrowLeft, LogoTimhut, WidgetSvg, SignSvg } from "assets/icons";
import ImgLogin from "assets/images/logo_login.png";
import defaultIcon from "assets/defaulticon.png";

import RegisterCompany from "./registerCompany";
import RegisterUser from "./registerUser";
import { registerSchema } from "./schema";
import { alertSuccess, alertError } from "utils/alert";

function RegisterPage() {
  const initialValues = {
    // USER
    organizationID: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryPhoneNumber: "65",
    phoneNumber: "",
    photo: "",
    // COMPANY
    companyName: "",
    uenNumber: "",
    address: "",
    employeeHeadCount: "",
    industryField: "",
    companyCountryPhoneNumber: "65",
    companyPhoneNumber: "",
    postalCode: "",
    country: "",
    companyImage: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);
  const [showComp, setShowComp] = useState({
    button: false,
    component: false,
    buttonSubmit: false,
  });

  const [errorMessage, setErrorMessage] = useState("");

  const { data: resCountry, isSuccess: isSuccessCountry } = useGetCSCQuery();

  useEffect(() => {
    if (isSuccessCountry && resCountry.data && resCountry.data.data) {
      setCountries(
        resCountry.data.data.map((country) => ({
          label: country.name,
          value: country.country_code,
        }))
      );
    }
  }, [isSuccessCountry, resCountry]);

  const handleContinue = (param, values, setTouched) => {
    const {
      firstName,
      lastName,
      userName,
      email,
      password,
      confirmPassword,
      countryPhoneNumber,
      phoneNumber,
      photo,
    } = values;

    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      userName.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      confirmPassword.trim() === "" ||
      countryPhoneNumber === "" ||
      phoneNumber.length === 0 ||
      phoneNumber === null ||
      (!photo && photo.trim() === "")
    ) {
      setTouched({
        firstName: true,
        lastName: true,
        userName: true,
        email: true,
        password: true,
        confirmPassword: true,
        countryPhoneNumber: true,
        phoneNumber: true,
        photo: true,
      });
      return;
    } else {
      setShowComp({
        button: param,
        component: param,
        buttonSubmit: param,
      });
    }
  };

  const handleSetCompanyUID = async (val) => {
    try {
      // const resp = await postCompany(val);
      const resp = await handlePostRegisterCompany(val);
      if (resp) {
        const uId = resp.data.data.uId;
        return uId;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerSchema}
      onSubmit={async (val) => {
        const formCompany = new FormData();
        const formUser = new FormData();

        formCompany.append("uenNumber", val.uenNumber);
        formCompany.append("companyImage", val.companyImage);
        formCompany.append("companyName", val.companyName);
        formCompany.append("address", val.address);
        formCompany.append("employeeHeadCount", val.employeeHeadCount);
        formCompany.append("industryField", val.industryField);
        formCompany.append("notes", val.notes);
        formCompany.append(
          "companyCountryPhoneNumber",
          val.companyCountryPhoneNumber
        );
        formCompany.append("companyPhoneNumber", val.companyPhoneNumber);
        formCompany.append("postalCode", val.postalCode);
        formCompany.append("country", val.country);

        const uId = await handleSetCompanyUID(formCompany);

        if (uId) {
          formUser.append("organizationID", uId);
          formUser.append("firstName", val.firstName);
          formUser.append("lastName", val.lastName);
          formUser.append("userName", val.userName);
          formUser.append("email", val.email);
          formUser.append("password", val.password);
          formUser.append("countryPhoneNumber", val.countryPhoneNumber);
          formUser.append("phoneNumber", val.phoneNumber);

          if (val.photo) {
            formUser.append("photo", val.photo);
          } else {
            // Append default user icon from imported asset
            const defaultFile = new File(
              [await fetch(defaultIcon).then((res) => res.blob())],
              "default-user.png",
              { type: "image/png" }
            );

            formUser.append("photo", defaultFile);
          }

          const userRegistered = await handlePostRegisterEmployee(formUser);

          if (userRegistered) {
            alertSuccess("Successfully Registering User");
            navigate("/login");
          } else {
            setErrorMessage("User registration failed. Please try again.");
          }
        } else {
          setErrorMessage("Company registration failed. Please try again.");
        }
      }}
    >
      {({ handleSubmit, values, setTouched }) => (
        <Onboarding source={ImgLogin}>
          <div className=" w-full flex flex-col gap-3">
            {showComp.button ? (
              <div className="w-full flex justify-end">
                <Button
                  style="solid"
                  type={"button"}
                  //   onClick={() => navigate("/login")}
                  className={"w-[200px]"}
                  onClick={() => handleContinue(false, values, setTouched)}
                  label={
                    <div className="flex gap-2 items-center">
                      <FiArrowLeft color={"white"} />
                      <h1>Back</h1>
                    </div>
                  }
                />
              </div>
            ) : (
              <div className="w-full flex justify-end">
                <Button
                  style="solid"
                  type={"button"}
                  onClick={() => navigate("/login")}
                  className={"w-[200px]"}
                  label={
                    <div className="flex gap-2 items-center">
                      <SignSvg color={"white"} />
                      <h1>Sign In</h1>
                    </div>
                  }
                />
              </div>
            )}

            <LogoTimhut />

            <div>
              {showComp.component ? (
                <RegisterCompany countries={countries} />
              ) : (
                <RegisterUser />
              )}
            </div>

            {showComp.buttonSubmit ? (
              <Button
                className={"w-full"}
                style="solid"
                type={"submit"}
                onClick={handleSubmit}
                label={
                  <div className="flex items-center gap-3 ">
                    <SignSvg />
                    <h1>Sign Up</h1>
                  </div>
                }
              />
            ) : (
              <Button
                style="solid"
                onClick={() => handleContinue(true, values, setTouched)}
                type={"button"}
                className={"w-full"}
                label={
                  <div className="flex gap-2 items-center">
                    <WidgetSvg color={"white"} />
                    <h1>Continue</h1>
                  </div>
                }
              />
            )}
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          </div>
        </Onboarding>
      )}
    </Formik>
  );
}

export default RegisterPage;
