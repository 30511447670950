import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { Header } from "@bluesilodev/timhutcomponents";
import { Header } from "@bluesilodev/timhutcomponents";

import { useGetAppQuery } from "services/commonAPI";
import {
  setLogoutReducer,
  setRoleReducer,
  setCurrentUserRole,
} from "store/reducer/user";
import { handleChangeApp } from "utils/utils";

import { LogoTimhut } from "assets/icons";
import CalendarIcon from "assets/Calendar.svg";

const LayoutDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, currentRole, token } = useSelector(
    (state) => state.userData
  );

  if (!currentUser) {
    navigate("/login");
  }

  if (currentUser?.role?.includes("SuperAdmin")) {
    window.location.href = "/employee/super/organization";
  }

  // dispatch(setCurrentUserRole({currentRole: ['Admin', 'Supervisor']}))

  console.log("current role", currentRole);

  const [apps, setApps] = useState([]);

  const { data: resApp, isSuccess: isSuccessApp } = useGetAppQuery();

  useEffect(() => {
    if (isSuccessApp && resApp.data) {
      setApps(resApp.data?.data);
    }
  }, [isSuccessApp, resApp]);

  useEffect(() => {
    // Update localStorage whenever currentRole changes
    if (currentRole) {
      localStorage.setItem("currentRole", currentRole);
    }
  }, [currentRole]);

  // Get currentUser from local storage
  // const currentLocalUser = JSON.parse(localStorage.getItem("currentUser"));

  // // Check if currentUser and role exist, and add "Supervisor" if not already present
  // if (currentLocalUser && currentLocalUser.role && !currentLocalUser.role.includes("Supervisor")) {
  //   currentLocalUser.role.push("Supervisor");

  //   // Update local storage with modified currentUser object
  //   localStorage.setItem("currentUser", JSON.stringify(currentLocalUser));
  //   console.log('updated current user', currentLocalUser);
  // }

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");
    navigate("/login");
  };

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  };

  const onClickApp = (link) => {
    handleChangeApp(link);
  };

  const onClickMyAccount = (link) => {
    handleChangeApp(`${link}/myAccount`);
  };

  const onClickSetting = (link) => {
    handleChangeApp(`${link}`);
  };

  const cleanedRole = currentRole.trim().toLowerCase();
  const moduleDisabled = cleanedRole === "superadmin";

  const locationOptions = [
    { label: "Cafe Aldi", value: "Cafe Aldi" },
    { label: "Cafe Jony", value: "Cafe Jony" },
  ];

  const jobOptions = {
    "Cafe Aldi": [
      { label: "Programmer", value: "Programmer" },
      { label: "Freelance", value: "Freelance" },
    ],
    "Cafe Jony": [
      { label: "Designer", value: "Designer" },
      { label: "Manager", value: "Manager" },
    ],
  };

  //   const notifications= [
  //     {
  //         id: '1',
  //         profileImage: CalendarIcon,
  //         notif: { subject: "New", message: 'New message received' },
  //         time: new Date().toLocaleString([], { weekday: 'long', hour: '2-digit', minute: '2-digit' }),
  //         read: true,
  //         location: "Cafe Aldi",
  //         jobPosition: "Programmer",
  //     },
  //     {
  //         id: '2',
  //         profileImage: CalendarIcon,
  //         notif: { subject: "Meeting", message: 'Meeting at 2 PM' },
  //         time: new Date().toLocaleString([], { weekday: 'long', hour: '2-digit', minute: '2-digit' }),
  //         read: false,
  //         location: "Cafe Aldi",
  //         jobPosition: "Freelance",
  //     },
  //     {
  //         id: '3',
  //         profileImage: CalendarIcon,
  //         notif: { subject: "Meeting", message: 'Meeting at 2 PM' },
  //         time: new Date().toLocaleString([], { weekday: 'long', hour: '2-digit', minute: '2-digit' }),
  //         read: false,
  //         location: "Cafe Jony",
  //         jobPosition: "Designer",
  //     },
  //     {
  //         id: '4',
  //         profileImage: CalendarIcon,
  //         notif: { subject: "Meeting", message: 'Meeting at 2 PM' },
  //         time: new Date().toLocaleString([], { weekday: 'long', hour: '2-digit', minute: '2-digit' }),
  //         read: false,
  //         location: "Cafe Jony",
  //         jobPosition: "Manager",
  //     },

  // ]

  return (
    <div className={`p-5`}>
      {apps.length > 0 && (
        <Header
          title={<LogoTimhut />}
          apps={apps}
          switchDisabled={currentUser?.role?.length < 2}
          userData={{
            name: currentUser?.userName,
            role: currentRole,
            language: "English",
            // switchRole: currentRole === "Admin" ? "User" : "Admin",
            switchRole:
              currentRole === "Admin"
                ? currentUser?.role.includes("Supervisor")
                  ? "Supervisor"
                  : "Employee"
                : currentUser?.role.includes("Admin")
                ? "Admin"
                : currentRole,
            image:
              currentUser?.photo?.length > 0 ? currentUser?.photo[0].link : "",
          }}
          onSwitch={onClickSwitch}
          onClickMyAccount={onClickMyAccount}
          onClickSetting={onClickSetting}
          onClickLogout={onClickLogout}
          onClickApp={onClickApp}
          jobOptions={jobOptions}
          locationOptions={locationOptions}
          moduleDisabled={moduleDisabled}
          // notificationData={notifications}
        />
      )}
      <Outlet />
    </div>
  );
};

export default LayoutDashboard;
