import React from "react";
import PropTypes from "prop-types";

function FiArrowRight({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M3.33325 8H12.6666"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.3335L12.6667 8.00016L8 12.6668"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiArrowRight.propTypes = {
  className: PropTypes.string,
};

FiArrowRight.defaultProps = {
  className: "w-[20px] h-[20px]",
};

export default FiArrowRight;
