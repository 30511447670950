import React from "react";
import PropTypes from "prop-types";

function FiArrowLeft({ className, color, ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M19 12H5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19L5 12L12 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FiArrowLeft.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

FiArrowLeft.defaultProps = {
  className: "w-[16px] h-[16px]",
  color: "black",
};

export default FiArrowLeft;
