import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Button, Onboarding } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";

import StepTwo from "./stepTwo";
import StepOne from "./stepOne";
import StepThree from "./stepThree";
import { signUpNewSchema } from "./signupEmployeeSchema";

import {
  postRegisterUser,
  postRegisterUserInformation,
} from "services/employeeAPI";
import { alertSuccess, alertError } from "utils/alert";

import ImgLogin from "assets/images/logo_login.png";
import { FiArrowLeft, LogoTimhut, FiArrowRight } from "assets/icons";
import defaultIcon from "assets/defaulticon.png";

function SignUpByEmployee() {
  const initialValues = {
    // STEP 1
    firstName: "",
    lastName: "",
    gender: "",
    religion: "",
    dateOfBirth: "",
    maritalStatus: "",
    race: "",
    bloodType: "",
    phoneNumber: "",
    email: "",
    photo: "",

    // STEP 2
    userName: "",
    password: "",
    confirmPassword: "",
    country: "",
    postalCode: "",
    address: "",

    // STEP 3
    identificationType: "",
    identityExpiredDate: "",
    nationality: "",
    identityNumber: 0,
    isPermanentDate: false,
    covid19VactionStatus: "",
    attachments: "",
  };
  // const queryClient = useQueryClient();

  const [stepPage, setStepPage] = useState(1);
  const [defaultPhotoBlob, setDefaultPhotoBlob] = useState(null);

  const navigate = useNavigate();
  // const { photo } = useSelector((state) => state.inputValRedux);

  const handleContinue = (param, values, setTouched) => {
    const {
      // STEP !
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      religion,
      dateOfBirth,
      maritalStatus,
      race,
      bloodType,
      photo,

      // STEP 2
      userName,
      password,
      confirmPassword,
      country,
      postalCode,
      address,

      // STEP 3
      identificationType,
      identityExpiredDate,
      nationality,
      identityNumber,
      // permanenDate,
      covid19VactionStatus,
      attachments,
    } = values;

    if (stepPage === 1) {
      if (
        firstName.trim() === "" ||
        lastName.trim() === "" ||
        phoneNumber.length < 3 ||
        gender.trim() === "" ||
        religion.trim() === "" ||
        dateOfBirth.trim() === "" ||
        maritalStatus.trim() === "" ||
        race.trim() === "" ||
        bloodType.trim() === "" ||
        photo.size >= 2000000
      ) {
        setTouched({
          firstName: true,
          lastName: true,
          phoneNumber: true,
          gender: true,
          religion: true,
          dateOfBirth: true,
          maritalStatus: true,
          race: true,
          bloodType: true,
          photo: true,
        });

        return;
      } else {
        // setShowComp({
        //   button: param,
        //   buttonSubmit: param,
        // });

        setStepPage(stepPage + 1);
      }
    }

    if (stepPage === 2) {
      if (
        email.trim() === "" ||
        userName.trim() === "" ||
        password.trim() === "" ||
        confirmPassword.trim() === "" ||
        country.trim() === "" ||
        postalCode.toString().length < 3 ||
        address.trim() === ""
      ) {
        setTouched({
          email: true,
          userName: true,
          password: true,
          confirmPassword: true,
          country: true,
          postalCode: true,
          address: true,
        });

        return;
      } else {
        // setShowComp({
        //   button: param,
        //   buttonSubmit: param,
        // });

        setStepPage(stepPage + 1);
      }
    }

    if (stepPage === 3) {
      if (
        identificationType.trim() === "" ||
        identityExpiredDate.trim() === "" ||
        nationality.trim() === "" ||
        identityNumber.toString().length < 3 ||
        covid19VactionStatus.trim() === "" ||
        attachments.trim() === ""
      ) {
        setTouched({
          identificationType: true,
          identityExpiredDate: true,
          nationality: true,
          identityNumber: true,
          covid19VactionStatus: true,
          attachments: true,
        });

        return;
      } else {
        // setShowComp({
        //   button: param,
        //   buttonSubmit: param,
        // });

        setStepPage(stepPage + 1);
      }
    }
  };

  useEffect(() => {
    const loadDefaultPhoto = async () => {
      const blob = await fetch(defaultIcon).then((res) => res.blob());

      setDefaultPhotoBlob(blob);
    };

    loadDefaultPhoto();
  }, []);

  // const generatePhotoToBlob = () => {
  //   if (Object.keys(photo).length > 0) {
  //     const parseData = JSON.parse(photo);

  //     const blob = new Blob([parseData.data], { type: parseData.type });
  //     const restoredFile = new File([blob], parseData.name, {
  //       type: parseData.type,
  //       lastModified: new Date().getTime(),
  //     });

  //     return restoredFile;
  //   }
  // };

  // const generateFile = generatePhotoToBlob();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signUpNewSchema}
      onSubmit={async (val) => {
        const formUser = new FormData();
        const formUserInformation = new FormData();

        const [dayIdentity, monthIdentity, yearIdentity] =
          val.identityExpiredDate.split("/");

        const identityExpiredDate = new Date(
          yearIdentity,
          monthIdentity - 1,
          dayIdentity
        );

        const userInformationData = JSON.stringify({
          identificationType: val.identificationType,
          identityExpiredDate: identityExpiredDate,
          nationality: val.nationality,
          identityNumber: val.identityNumber,
          isPermanentDate: val.isPermanentDate,
          covid19VaksinationStatus: val.covid19VactionStatus,
          attachments: val.attachments,
        });

        formUserInformation.append("identificationDoc", userInformationData);
        formUserInformation.append("attachments", val.attachments);

        try {
          const respUserInformation = await postRegisterUserInformation(
            formUserInformation
          );

          const userInformationID = respUserInformation.data.data.uId;

          if (userInformationID) {
            const [day, month, year] = val.dateOfBirth.split("/");
            const dateOfBirth = new Date(year, month - 1, day);

            formUser.append("userInformationID", userInformationID);
            formUser.append("organizationID", "-");
            formUser.append("firstName", val.firstName);
            formUser.append("lastName", val.lastName);
            formUser.append("gender", val.gender);
            formUser.append("religion", val.religion);
            formUser.append("dateOfBirth", dateOfBirth);
            formUser.append("maritalStatus", val.maritalStatus);
            formUser.append("race", val.race);
            formUser.append("bloodType", val.bloodType);
            formUser.append("phoneNumber", val.phoneNumber);
            formUser.append("email", val.email);
            formUser.append("employeeID", "-");

            if (val.photo) {
              formUser.append("photo", val.photo.file);
            } else {
              // Append default user icon from imported asset
              const defaultFile = new File(
                [defaultPhotoBlob],
                "default-user.png",
                {
                  type: "image/png",
                }
              );
              formUser.append("photo", defaultFile);
            }

            formUser.append("userName", val.userName);
            formUser.append("password", val.password);
            formUser.append("confirmPassword", val.confirmPassword);
            formUser.append("country", val.country);
            formUser.append("postalCode", val.postalCode);
            formUser.append("address", val.address);
            formUser.append("countryPhoneNumber", 0);

            const respUser = await postRegisterUser(formUser);

            if (respUser && respUserInformation) {
              alertSuccess("Sign Up Success");
              navigate("/login");
            }
          }
        } catch (error) {
          alertError(error);
          console.log("Error: ", error);
        }
      }}
    >
      {({ handleSubmit, values, setTouched }) => {
        return (
          <Onboarding source={ImgLogin}>
            {/* <h1>STEP PAGE {stepPage}</h1> */}

            <div className=" w-full flex flex-col gap-3">
              {
                <div className="w-full flex justify-end">
                  <Button
                    style="solid"
                    type={"button"}
                    //   onClick={() => navigate("/login")}
                    className={"w-[200px]"}
                    onClick={() => {
                      if (stepPage === 1) {
                        navigate("/login");
                      }
                      if (stepPage <= 3) {
                        setStepPage(stepPage - 1);
                      }
                    }}
                    label={
                      <div className="flex gap-2 items-center text-white ">
                        {stepPage === 1 && (
                          <div className="flex gap-3 items-center">
                            <h1>Sign In</h1>
                          </div>
                        )}

                        {stepPage === 2 && (
                          <div className="flex gap-3 items-center">
                            <FiArrowLeft />
                            <h1>Back</h1>
                          </div>
                        )}

                        {stepPage === 3 && (
                          <div className="flex gap-3 items-center">
                            <FiArrowLeft />
                            <h1>Back</h1>
                          </div>
                        )}
                      </div>
                    }
                  />
                </div>
              }

              <LogoTimhut />

              <div>
                {stepPage === 1 && <StepOne />}
                {stepPage === 2 && <StepTwo />}
                {stepPage === 3 && <StepThree />}
              </div>

              {stepPage !== 3 ? (
                <Button
                  label={
                    <div className="flex items-center gap-3 text-white ">
                      <div>
                        {stepPage !== 3 && (
                          <div className="flex gap-3 items-center">
                            <FiArrowRight className="text-white" />
                            <h1>Next</h1>
                          </div>
                        )}
                      </div>

                      {/* <h1>{stepPage === 3 && "Submit"}</h1> */}
                    </div>
                  }
                  onClick={() => handleContinue(true, values, setTouched)}
                  type={"button"}
                  style="solid"
                  className={"w-full text-white"}
                />
              ) : (
                <Button
                  label={
                    <div className="flex items-center gap-3 text-white ">
                      <h1>Submit</h1>
                    </div>
                  }
                  onClick={handleSubmit}
                  type={"submit"}
                  style="solid"
                  className={"w-full text-white"}
                />
              )}
            </div>
          </Onboarding>
        );
      }}
    </Formik>
  );
}

export default SignUpByEmployee;
