import React from "react";
import {
  InputText,
  InputSelect,
  InputDate,
  CountrySelect,
  InputNumber,
  Accordion,
} from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";

// import UploadImage from "./uploadImage";
import UploadImg2 from "./uploadImg2";

function StepOne() {
  const { values, handleBlur, errors, touched, handleChange, setFieldValue } =
    useFormikContext();

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex flex-col  w-full ">
        <div className="flex flex-col gap-3">
          <Accordion
            title={"Profile Picture"}
            icons={[]}
            children={
              <div>
                {/* <UploadImage /> */}
                <UploadImg2
                  name="photo"
                  setFieldValue={setFieldValue}
                  value={values.photo}
                  onBlur={handleBlur}
                  touched={touched}
                  error={errors.photo && touched.photo && errors.photo}
                />
              </div>
            }
          />

          <div className="flex gap-3">
            <InputText
              id="firstName"
              title={"First Name"}
              placeholder="Enter First Name"
              value={values.firstName}
              onChange={handleChange}
              required={true}
              label={null}
              onBlur={handleBlur}
              error={
                <>
                  {errors.firstName && touched.firstName && (
                    <h1 className="text-red-500">{errors.firstName}</h1>
                  )}
                </>
              }
            />
            <InputText
              id="lastName"
              title={"Last Name"}
              placeholder="Enter Last Name"
              onChange={handleChange}
              value={values.lastName}
              required={true}
              label={null}
              onBlur={handleBlur}
              error={
                <>
                  {errors.lastName && touched.lastName && (
                    <h1 className="text-red-500">{errors.lastName}</h1>
                  )}
                </>
              }
            />
          </div>

          <div className="flex gap-3">
            <InputSelect
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              title={"Gender"}
              name="gender"
              value={values.gender}
              required={true}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                <>
                  {errors.gender && touched.gender && (
                    <div className="text-red-500">{errors.gender}</div>
                  )}
                </>
              }
            />

            <InputSelect
              options={[
                { label: "Christianity", value: "Christianity" },
                { label: "Islam", value: "Islam" },
                { label: "Folk Religions", value: "Folk Religions" },
                { label: "Hinduism", value: "Hinduism" },
                { label: "Buddhism", value: "Buddhism" },
                { label: "No Religion", value: "No Religion" },
                { label: "Other", value: "Other" },
              ]}
              title={"Religion"}
              required={true}
              name="religion"
              value={values.religion}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                <>
                  {errors.religion && touched.religion && (
                    <div className="text-red-500">{errors.religion}</div>
                  )}
                </>
              }
            />
          </div>

          <div className="flex gap-3">
            <InputDate
              label={"Date Of Birth"}
              name={"dateOfBirth"}
              required={true}
              setFieldValue={setFieldValue}
              // disabled={disabled ? disabled : false}
              value={values.dateOfBirth}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={
                errors.dateOfBirth &&
                touched.dateOfBirth && (
                  <div className="text-red-500 text-sm">
                    {errors.dateOfBirth}
                  </div>
                )
              }
            />
            <InputSelect
              options={[
                { label: "Single", value: "Single" },
                { label: "Married", value: "Married" },
              ]}
              title={"Marital Status"}
              required={true}
              name="maritalStatus"
              value={values.maritalStatus}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                <>
                  {errors.religion && touched.maritalStatus && (
                    <div className="text-red-500">{errors.maritalStatus}</div>
                  )}
                </>
              }
            />
          </div>

          <InputSelect
            options={[
              { label: "Asian", value: "Asian" },
              { label: "White (Caucasian)", value: "White (Caucasian)" },
              {
                label: "Black or African American",
                value: "Black or African American",
              },
              {
                label: "Hispanic or Latino",
                value: "Hispanic or Latino",
              },
              {
                label: "Native American or Alaska Native",
                value: "Native American or Alaska Native",
              },
              {
                label: "Native Hawaiian or Pacific Islander",
                value: "Native Hawaiian or Pacific Islander",
              },
              {
                label: "Middle Eastern or North African (MENA)",
                value: "Middle Eastern or North African (MENA)",
              },
              {
                label: "Mixed or Multiracial",
                value: "Mixed or Multiracial",
              },
            ]}
            title={"Race"}
            required={true}
            name="race"
            value={values.race}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              <>
                {errors.religion && touched.race && (
                  <div className="text-red-500">{errors.race}</div>
                )}
              </>
            }
          />

          <InputSelect
            options={[
              { label: "A", value: "A" },
              { label: "B", value: "B" },
              { label: "O", value: "O" },
              { label: "A+", value: "A+" },
              { label: "B+", value: "B+" },
              { label: "O-", value: "O-" },
              { label: "AB", value: "AB" },
              { label: "AB+", value: "AB+" },
              { label: "AB-", value: "AB-" },
            ]}
            title={"Blood Type"}
            required={true}
            name="bloodType"
            value={values.bloodType}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              <>
                {errors.religion && touched.bloodType && (
                  <div className="text-red-500">{errors.bloodType}</div>
                )}
              </>
            }
          />

          <div className="flex gap-3">
            <div className="w-[30%]">
              <CountrySelect
                onChange={(value) =>
                  setFieldValue("countryPhoneNumber", value.code)
                }
                value={`${values.countryPhoneNumber}`}
              />
            </div>

            <div className="w-[70%]">
              <InputText
                type={"number"}
                title={"Phone Number"}
                onChange={handleChange}
                placeholder="Enter Phone Number"
                value={values.phoneNumber}
                required={true}
                id="phoneNumber"
                name="phoneNumber"
                onBlur={handleBlur}
                error={
                  <>
                    {errors.phoneNumber && touched.phoneNumber && (
                      <div className="text-red-500">{errors.phoneNumber}</div>
                    )}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepOne;
