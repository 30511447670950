import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo } from "react";
import { cn } from "utils/common";

// const Tabs = ({
//     options,
//     value,
//     width,
//     onClick,
//     day,
//     ...props
// }) => {
//     const [currentTab, setCurrentTab] = useState(value || options[0]?.id);

//     useEffect(() => {
//         setCurrentTab(value || options[0]?.id);
//     }, [value, options]);

//     const handleClick = (e) => {
//         setCurrentTab(e.target.id);
//         if (onClick) onClick(e); // Call the onClick prop if it is provided
//     };

//     return (
//         <div className="flex flex-col w-full gap-1" {...props}>
//             <ul className="flex justify-around w-full p-1 space-x-1 text-xs font-medium text-center text-gray-500 border-2 border-gray-100 rounded-md dark:text-gray-400">
//                 {options.map((option, i) => (
//                     <button
//                         key={i}
//                         id={option.id}
//                         onClick={handleClick}
//                         className={`inline-block px-3 py-2 rounded-lg ${currentTab === option.id
//                             ? "bg-orange-500 text-white"
//                             : "bg-gray-300 text-gray-500"
//                             } hover:text-white hover:bg-orange-500`}
//                         style={{ minWidth: width ? width : 'auto' }}
//                     >
//                         {option.value}
//                     </button>
//                 ))}
//             </ul>
//             <div className="w-full p-1 overflow-y-auto border-2 border-gray-100 rounded-lg">
//                 {options.map(option => (
//                     currentTab === option.id && (
//                         <div key={option.id}>
//                             {option.dataList && option.dataList.map((dataItem) => {
//                                 const itemDay = dataItem.date.split('-')[2]; // Get the day part of the date
//                                 const isHighlighted = itemDay === day; // Check if the current day matches the passed day prop
//                                 return (
//                                     <div key={itemDay} className={`w-full flex mb-2 ${isHighlighted ? 'bg-orange-200' : ''}`}>
//                                         <div className="flex justify-center w-1/4">
//                                             <img src={dataItem.imgSrc} alt="Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
//                                         </div>
//                                         <div className="flex flex-col justify-between w-3/4 pl-2">
//                                             <p className="text-sm font-semibold">{dataItem.name}</p>
//                                             <p className="text-xs text-gray-400">{dataItem.date}</p>
//                                         </div>
//                                     </div>
//                                 );
//                             })}
//                         </div>
//                     )
//                 ))}
//             </div>
//         </div>
//     );
// };

const Tabs = ({
  options,
  value,
  width,
  onClick,
  day,
  currentTab,
  setCurrentTab,
  ...props
}) => {
  const handleClick = (e) => {
    setCurrentTab(e.target.id);
    if (onClick) onClick(e); // Call the onClick prop if it is provided
  };

  return (
    <div className="flex flex-col w-full gap-1" {...props}>
      <ul className="flex justify-around w-full gap-1 p-1 text-xs font-medium text-center text-gray-500 border border-black rounded-md dark:text-gray-400">
        {options.map((option) => (
          <TabButton
            key={option.id}
            id={option.id}
            value={option.value}
            onClick={handleClick}
            currentTab={currentTab}
          />
        ))}
      </ul>
      <div className="w-full p-1 overflow-y-auto border-2 border-gray-100 rounded-lg">
        {options.map(
          (option) =>
            currentTab === option.id && (
              <div key={option.id}>
                {option?.dataList &&
                option?.dataList?.filter((dataItem) => {
                  if (!day) return true;

                  const itemDate = dayjs(dataItem.date).format(
                    option.id === "3" ? "DD-MM" : "DD-MM-YYYY"
                  );
                  const selectedDate = dayjs(day).format(
                    option.id === "3" ? "DD-MM" : "DD-MM-YYYY"
                  );

                  return itemDate === selectedDate;
                }).length > 0 ? (
                  option?.dataList?.map((dataItem, index) => {
                    const itemDate = dayjs(dataItem.date);
                    const isBirthdayTab =
                      option.id === "3"
                        ? itemDate.format("DD-MM") ===
                          dayjs(day).format("DD-MM")
                        : false;
                    const isShow =
                      itemDate.isSame(dayjs(day), "day") ||
                      isBirthdayTab ||
                      !day;
                    return (
                      <div
                        key={index}
                        className={cn("w-full flex mb-1", !isShow && "hidden")}
                      >
                        <div className="flex justify-center w-1/4">
                          <img
                            src={dataItem.imgSrc}
                            alt="Thumbnail"
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                        </div>
                        <div className="flex flex-col justify-between w-3/4 pt-1 pb-1 pl-2">
                          <p className="mb-1 text-sm font-semibold">
                            {option.id === "2"
                              ? `${dataItem.name} - ${dataItem.employeeName}`
                              : dataItem.name}
                          </p>
                          <p className="text-xs text-gray-400">
                            {itemDate.format("DD MMMM YYYY")}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  // Render 'No event' when no dataItems are available
                  <div className="flex items-center justify-center w-full p-2">
                    <p className="mb-1 text-sm font-semibold">No Event</p>
                    <p className="text-xs text-gray-400"></p> {/* Empty text */}
                  </div>
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};

const TabButton = memo(({ id, value, onClick, currentTab }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`inline-block py-3 rounded-md flex-1 font-semibold ${
        currentTab === id
          ? "bg-[#dd7224] text-white"
          : "bg-gray-300 text-gray-500"
      } hover:text-white hover:bg-[#dd7224]`}
    >
      {value}
    </button>
  );
});

Tabs.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onClick: PropTypes.func,
  day: PropTypes.string,
};

Tabs.defaultProps = {
  options: [],
  value: "",
  onClick: () => {},
  day: "",
};

export default memo(Tabs);
