import React from "react";
import InputCheckboxCustom from "./inputCheckboxCustom";

function InputWeekly({
  handleCheckboxChange,
  values,
  touched,
  errors,
  disabled,
}) {
  return (
    <div className="flex gap-3 mt-5">
      <InputCheckboxCustom
        id={"repeatBy.repeatValue"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"sunday"}
        title={"S"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />
      <InputCheckboxCustom
        id={"repeat2"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"monday"}
        title={"M"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />

      <InputCheckboxCustom
        id={"repeat3"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"tuesday"}
        title={"T"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />

      <InputCheckboxCustom
        id={"repeat4"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"wednesday"}
        title={"W"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />

      <InputCheckboxCustom
        id={"repeat5"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"thursday"}
        title={"T"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />

      <InputCheckboxCustom
        id={"repeat6"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"friday"}
        title={"F"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />

      <InputCheckboxCustom
        id={"repeat7"}
        onChange={handleCheckboxChange}
        value={values.repeatBy.repeatValue}
        checkedValue={"saturday"}
        title={"S"}
        error={
          touched.repeatBy &&
          touched.repeatBy.repeatValue &&
          errors.repeatBy &&
          errors.repeatBy.repeatValue
        }
        disabled={disabled}
      />
    </div>
  );
}

export default InputWeekly;
