import React, { useRef } from "react";
import { CameraIcon } from "assets/icons";

function UploadImg2({
  name,
  touched,
  onBlur,
  value,
  error,
  setFieldValue,
  ...props
}) {
  const inputRef = useRef();

  const handleClickInput = () => {
    if (inputRef.current) {
      touched.photo = true;
      inputRef.current.click();
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileData = {
          file,
          data: reader.result, // Simpan sebagai Base64
        };

        console.log("FILE: ", fileData);

        setFieldValue(name, fileData);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div
        onClick={handleClickInput}
        className="relative z-[999] w-[128px] h-[128px] rounded-full border-[1px] border-[#DD7224] cursor-pointer overflow-hidden"
      >
        {value && (
          <img
            src={value?.data}
            alt="img"
            className="w-full h-full object-cover rounded-full"
          />
        )}

        {/* Ikon tetap ada di atas gambar */}
        <div
          className={`absolute inset-0 z-10 flex justify-center items-center w-full h-full rounded-full transition-all ${
            value ? "bg-transparent" : "bg-[#F5D5BE]"
          }`}
        >
          <CameraIcon className="text-white w-8 h-8 opacity-80" />
        </div>

        <input
          id={name}
          ref={inputRef}
          accept="image/png, image/jpeg"
          type="file"
          className="hidden"
          onChange={handleChange}
          onBlur={onBlur}
          {...props}
        />
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
}

export default UploadImg2;
