import { useQuery } from "@tanstack/react-query";

import { alertError } from "utils/alert";
import { customAxios } from "utils/axios";

const handlePostPunchIn = async (val) => {
  try {
    const res = await customAxios.post(`/api/attendance/punch-in`, val);
    return res.data;
  } catch (error) {
    console.error("Punch In error:", error);
    alertError(error.response?.data?.message ?? "Failed to Punch In");
    throw error;
  }
};

const handlePostPunchOut = async (val) => {
  try {
    const res = await customAxios.post(`/api/attendance/punch-out`, val);
    return res.data;
  } catch (error) {
    console.error("Punch Out error:", error);
    alertError(error.response?.data?.message ?? "Failed to Punch Out");
    throw error;
  }
};

const handlePostBreak = async (val) => {
  try {
    const res = await customAxios.post(`/api/attendance/break`, val);
    return res.data;
  } catch (error) {
    console.error("Break error:", error);
    alertError(error.response?.data?.message ?? "Failed to Break");
    throw error;
  }
};

const handlePostReturnBreak = async (val) => {
  try {
    const res = await customAxios.post(
      `/api/attendance/return-from-break`,
      val
    );
    return res.data;
  } catch (error) {
    console.error("Return From Break error:", error);
    alertError(error.response?.data?.message ?? "Failed to Return From Break");
    throw error;
  }
};

// function for fetching latest attendance data
const fetchAndSetWidgets = async (
  token,
  dispatch,
  setIsTimerRunning,
  setattendanceDataStates
) => {
  if (!token) return;

  console.log("Fetch Token:", token);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log("Request Headers:", config.headers);

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/attendance/employee/latest`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...config.headers,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Failed to fetch latest attendance data");
    }
    const responseData = await res.json();
    return responseData;
  } catch (error) {
    console.error(
      "Error fetching latest attendance data:",
      error.message || error
    );
    alertError(error.message || "Failed to get latest attendance data");
  }
};

const useGetAttendanceByUser = () => {
  return useQuery(["getAttendanceData"], async () => {
    const res = await customAxios.get(`/api/attendance/employee/latest`);
    return res.data;
  });
};

const getAllAttendanceByDate = async (val) => {
  try {
    const res = await customAxios.get(
      `/api/attendance/getDate?startDate=${val.startDate}&endDate=${val.endDate}`
    );
    return res.data;
  } catch (error) {
    console.error("Error fetching attendance data:", error);
    throw error; // Rethrow the error or handle it as needed
  }
};

const useGetAttendanceSettingByUser = () => {
  return useQuery(["getAttendanceSetting"], async () => {
    const res = await customAxios.get(`/api/attendance/attendance-setting`);
    return res.data;
  });
};

export {
  fetchAndSetWidgets,
  getAllAttendanceByDate,
  handlePostBreak,
  handlePostPunchIn,
  handlePostPunchOut,
  handlePostReturnBreak,
  useGetAttendanceByUser,
  useGetAttendanceSettingByUser,
};
