import React, { useEffect } from "react";
import { ModalDialog } from "@bluesilodev/timhutcomponents";

import { useGetByIdShift } from "services/schedulingAPI";
import FormShift from "./formShift";

function ModalDetailsShift({ id, setIsModal }) {
  const { data: dataShiftId, isLoading: loadingShift } = useGetByIdShift({
    uId: id,
  });

  if (loadingShift) {
    return <div>Loading...</div>;
  }

  const setToApi =
    dataShiftId &&
    Object.keys(dataShiftId?.data?.data).length > 0 &&
    dataShiftId.data.data;

  const initialValue = {
    shiftName: setToApi?.shiftName || "",
    color: setToApi?.color || "red",
    startTime: setToApi?.startTime || "",
    endTime: setToApi?.endTime || "",

    startBreakTime: setToApi?.startBreakTime || "",
    endBreakTime: setToApi?.endBreakTime || "",

    // REPEAT JUST ACCEPT VALUE ARRAY SEND TO API OBJECT > FROM - TO
    repeatDate: setToApi?.repeatDate || [],
    repeatBy: {
      repeatType: setToApi?.repeatBy?.repeatType || "Daily",
      repeatValue: setToApi?.repeatBy?.repeatValue || [],
    },
    locationId: setToApi?.locationId || "",

    employees: setToApi?.employees || [],
  };

  return (
    <ModalDialog
      className={"w-[700px] h-[650px]"}
      title={"Details Shift"}
      onClose={() => setIsModal(false)}
    >
      <FormShift initialValue={initialValue} />
    </ModalDialog>
  );
}

export default ModalDetailsShift;
