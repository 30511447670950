function parseTime(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function generateTime(startTime, endTime) {
  const start = parseTime(startTime);
  const end = parseTime(endTime);

  // Calculate the difference in milliseconds
  const diffMs = end - start;

  // Convert the difference to hours and minutes
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  const resulDif = diffHours < 0 ? 0 : diffHours;
  return {
    hours: resulDif,
    minutes: diffMinutes,
  };
}
